import React from 'react';

function CSharpLogo(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      aria-hidden='true'
      width='72'
      height='72'
      viewBox='0 0 340 378'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M158.167 2.502C150.135 4.76 148.205 5.812 82 44.013 12.831 83.925 11.995 84.521 5.228 98.692L1.5 106.5v165l3.728 7.808c6.797 14.235 7.384 14.65 78.772 55.756 76.299 43.933 71.084 41.424 86 41.381 13.647-.039 15.215-.595 36.5-12.943 7.7-4.468 36.204-20.913 63.342-36.545 52.729-30.374 55.406-32.179 61.334-41.351C339.297 273.041 339 276.712 339 189c0-87.712.297-84.041-7.824-96.606-5.928-9.172-8.605-10.977-61.334-41.351C242.704 35.411 214.2 18.95 206.5 14.464 184.503 1.648 172.115-1.418 158.167 2.502M87.929 129.367c-9.93 2.678-19.154 10.497-24.085 20.417-3.685 7.414-3.661 71.065.03 78.496 10.37 20.877 35.098 27.776 53.885 15.032 19.078-12.942 21.674-42.283 3.741-42.283-6.196 0-12.5 6.137-12.5 12.169 0 10.617-13.203 15.734-20.25 7.848L86 217.968v-57.936l2.75-3.078c7.047-7.886 20.25-2.769 20.25 7.848 0 6.032 6.304 12.169 12.5 12.169 13.356 0 16.388-17.097 5.805-32.73-8.589-12.686-24.791-18.806-39.376-14.874m99.983.913c-4.692 3.162-5.88 6.078-5.897 14.47L182 152h-6.532c-11.322 0-17.501 4.39-17.433 12.385.077 8.874 4.866 12.599 16.215 12.608L182 177v24l-7.75.007c-11.349.009-16.138 3.734-16.215 12.608-.068 7.995 6.111 12.385 17.433 12.385H182l.015 7.25c.027 13.279 7.631 19.859 17.525 15.164 5.079-2.41 6.754-5.788 7.268-14.652l.449-7.762H230v6.766c0 8.126 2.123 12.758 7.062 15.407 9.798 5.255 17.927-1.51 17.934-14.923L255 226h6.532c11.207 0 17.468-4.384 17.468-12.231C279 205 273.861 201 262.596 201H255v-24h7.596c11.265 0 16.404-4 16.404-12.769 0-7.847-6.261-12.231-17.468-12.231H255l-.004-7.25c-.007-13.413-8.136-20.178-17.934-14.923-4.939 2.649-7.062 7.281-7.062 15.407V152H207.257l-.449-7.762c-.796-13.744-9.582-20.235-18.896-13.958M207 189v12h23v-24h-23v12'
        fillRule='evenodd'
      />
    </svg>
  );
}

CSharpLogo.displayName = 'CSharpLogo';
export default CSharpLogo;
